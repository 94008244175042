import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Mobile, PC, Tablet } from "../component/MediaQuery";
import Typography from "../component/Typography";
import i18n from "../../locales";

const FooterWrap = styled.footer`
    min-width: 1200px;
    background-color: #fbfbfb;
    padding: 54px 40px 24px;
    > div {
        max-width: 1920px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px 0;
        > article {
            display: flex;
            align-items: center;
            gap: 0 18px;
            > a {
                width: 48px;
                height: 48px;
                border-radius: 24px;
                background-color: white;
                filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
            }
        }
        > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px 0;
            div {
                display: flex;
                align-items: center;
                gap: 0 24px;
            }
        }
        > figure {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 0 4px;
            color: #ededed;
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0 4px;
            }
            a {
                color: #999999;
                font-size: 13px;
                text-decoration: none;
                &:hover {
                    color: #7a7a7a;
                }
            }
        }
        > aside {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0 16px;
            color: #d6d6d6;
            button {
                font-size: 13px;
                color: #7a7a7a;
                border: 0;
                background-color: transparent;
                padding: 0;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover {
                    color: #568dfd;
                }
            }
            span {
                font-size: 10px;
            }
        }
    }
    @media (min-width: 600px) and (max-width: 1199px) {
        min-width: 600px;
        padding: 48px 30px 32px;
        > div {
            > section {
                div {
                    gap: 0 16px;
                }
            }
        }
    }

    @media (max-width: 599px) {
        min-width: 100%;
        padding: 48px 20px 80px;
        > div {
            > section {
                div {
                    gap: 0 16px;
                }
                div:not(:first-of-type) {
                    flex-direction: column;
                    gap: 8px 0;
                }
            }
            > figure {
                flex-direction: column;
            }
        }
    }
`;

interface FooterProps {
    onChangeLanguage: (value: string) => void;
}

const FooterKr = (props: FooterProps) => {
    const { onChangeLanguage } = props;
    const navigate = useNavigate();

    return (
        <FooterWrap>
            <div>
                <article>
                    <a
                        href="https://www.youtube.com/channel/UChfeSGMCYKsC-eGl3qm5K4w"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/assets/images/sns_youtube.svg" alt="youtube" />
                    </a>
                    <a
                        href="https://www.facebook.com/gripappofficial/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/assets/images/sns_facebook.svg" alt="facebook" />
                    </a>
                    <a
                        href="https://www.instagram.com/grip_app_official/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/assets/images/sns_instagram.svg" alt="instagram" />
                    </a>
                    <a
                        href="https://www.linkedin.com/showcase/gripcloud/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/assets/images/sns_linkedin.svg" alt="linkedin" />
                    </a>
                </article>
                <section>
                    <PC>
                        <div>
                            <Typography label="(주) 그립컴퍼니" size={13} color="#7A7A7A" />
                            <Typography label="대표이사 : 김한나" size={13} color="#7A7A7A" />
                            <Typography
                                label="개인정보관리책임자 : 임삼열"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="사업자 등록번호 : 239-87-01063"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="통신판매업신고 : 2022-성남분당A-0605"
                                size={13}
                                color="#7A7A7A"
                            />
                        </div>
                        <div>
                            <Typography
                                label="주소 : 경기도 성남시 분당구 판교역로 152, 11층"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography label="대표번호 : 1522-3227" size={13} color="#7A7A7A" />
                            <Typography
                                label="이메일 : cloud.bd@gripcorp.co"
                                size={13}
                                color="#7A7A7A"
                            />
                        </div>
                    </PC>
                    <Tablet>
                        <div>
                            <Typography label="(주) 그립컴퍼니" size={13} color="#7A7A7A" />
                            <Typography label="대표이사 : 김한나" size={13} color="#7A7A7A" />
                            <Typography
                                label="개인정보관리책임자 : 임삼열"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="사업자 등록번호 : 239-87-01063"
                                size={13}
                                color="#7A7A7A"
                            />
                        </div>
                        <div>
                            <Typography
                                label="통신판매업신고 : 2022-성남분당A-0605"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="주소 : 경기도 성남시 분당구 판교역로 152, 11층"
                                size={13}
                                color="#7A7A7A"
                            />
                        </div>
                        <div>
                            <Typography label="대표번호 : 1522-3227" size={13} color="#7A7A7A" />
                            <Typography
                                label="이메일 : cloud.bd@gripcorp.co"
                                size={13}
                                color="#7A7A7A"
                            />
                        </div>
                    </Tablet>
                    <Mobile>
                        <div>
                            <Typography label="(주) 그립컴퍼니" size={13} color="#7A7A7A" />
                            <Typography label="대표이사 : 김한나" size={13} color="#7A7A7A" />
                        </div>
                        <div>
                            <Typography
                                label="개인정보관리책임자 : 임삼열"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="사업자 등록번호 : 239-87-01063"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="통신판매업신고 : 2022-성남분당A-0605"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography
                                label="주소 : 경기도 성남시 분당구 판교역로 152, 11층"
                                size={13}
                                color="#7A7A7A"
                            />
                            <Typography label="대표번호 : 1522-3227" size={13} color="#7A7A7A" />
                            <Typography
                                label="이메일 : cloud.bd@gripcorp.co"
                                size={13}
                                color="#7A7A7A"
                            />
                        </div>
                    </Mobile>
                </section>
                <figure>
                    <PC>
                        <Link
                            to="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2398701063"
                            target="_blank"
                        >
                            사업자정보확인
                        </Link>
                        <span>|</span>
                        <Link
                            to="https://blog.gripcloud.show/%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80"
                            target="_blank"
                        >
                            이용약관
                        </Link>
                        <span>|</span>
                        <Link
                            to="https://hubspot.gripcloud.show/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8"
                            target="_blank"
                        >
                            <b>개인정보처리방침</b>
                        </Link>
                    </PC>
                    <Tablet>
                        <div>
                            <Link
                                to="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2398701063"
                                target="_blank"
                            >
                                사업자정보확인
                            </Link>
                            <span>|</span>
                            <Link
                                to="https://blog.gripcloud.show/%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80"
                                target="_blank"
                            >
                                이용약관
                            </Link>
                            <span>|</span>
                            <Link
                                to="https://hubspot.gripcloud.show/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8"
                                target="_blank"
                            >
                                <b>개인정보처리방침</b>
                            </Link>
                        </div>
                    </Tablet>
                    <Mobile>
                        <div>
                            <Link
                                to="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2398701063"
                                target="_blank"
                            >
                                사업자정보확인
                            </Link>
                            <span>|</span>
                            <Link
                                to="https://blog.gripcloud.show/%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80"
                                target="_blank"
                            >
                                이용약관
                            </Link>
                            <span>|</span>
                            <Link
                                to="https://hubspot.gripcloud.show/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8"
                                target="_blank"
                            >
                                <b>개인정보처리방침</b>
                            </Link>
                        </div>
                    </Mobile>
                </figure>
                <Typography label="© 2018 GripCompany" size={13} color="#7A7A7A" />
                <aside>
                    <button
                        onClick={() => {
                            onChangeLanguage("ko");
                            i18n.changeLanguage("ko");
                            navigate("kr");
                        }}
                    >
                        한국어
                    </button>
                    <span>|</span>
                    <button
                        onClick={() => {
                            onChangeLanguage("en");
                            i18n.changeLanguage("en");
                            navigate("en");
                        }}
                    >
                        English
                    </button>
                    <span>|</span>
                    <button
                        onClick={() => {
                            onChangeLanguage("ja");
                            i18n.changeLanguage("ja");
                            navigate("jp");
                        }}
                    >
                        日本語
                    </button>
                </aside>
            </div>
        </FooterWrap>
    );
};

export default FooterKr;
