import React from "react";
import styled, { keyframes } from "styled-components";
import { media } from "../../../../pages/home/Home.style";

const ripple = keyframes`
    to {
        transform: rotate(1turn);
    }
`;

export const FeatureCardWrap = styled.div`
  position: relative;
  background-color: #1f1f1f;
  border-radius: 24px;
  margin-right: 24px;
  overflow: hidden;

  display: grid;

  grid-template-columns: 0.75fr 1.25fr;

  ${media.belowTa} {
    grid-template-columns: 1fr;
    grid-template-rows: 20rem 1fr;
  }
  > legend {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 70px;
    font-weight: 800;
    line-height: 1;
    color: #4f4f4f;
  }
  > div {
    height: 100%;
    padding: 130px 0 0 80px;
    position: relative;
    z-index: 2;

    a {
      font-size: 16px;
      font-weight: 600;
      color: #568dfd;
      text-decoration: none;
      margin-top: 34px;
      display: flex;
      align-items: center;
    }
    .game-tab {
      display: inline-flex;
      flex-flow: row nowrap;
      gap: 1.5rem;
      margin-top: 1.56rem;

      ${media.ta} {
        margin-top: 7rem;
        gap: 1.25rem;
      }

      ${media.mo} {
        margin-top: 7.69rem;
        gap: 1rem;
      }
      &::before {
        content: "";
        height: auto;
        width: 2px;
        flex-shrink: 0;

        border-radius: 0.1875rem;
        background: #969696;
      }

      > div {
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, 12rem));
        gap: 1rem 0;

        ${media.ta} {
          grid-template-columns: repeat(1, minmax(auto, 12.5rem));
          gap: 0.5rem;
        }
        ${media.mo} {
          gap: 0.25rem;
          grid-template-columns: 1fr;
        }

        .game-item {
          display: inline-flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;

          ${media.mo} {
            gap: 0.38rem;
          }
          img {
            width: 2.5rem;
            height: 2.5rem;
            object-fit: contain;

            ${media.mo} {
              width: 2rem;
              height: 2rem;
            }
          }
          p {
            color: #b3b3b3;
          }
        }
      }
    }
  }
  > aside {
    position: absolute;
    bottom: 30px;
    right: 40px;
    z-index: 10;
    @media (min-width: 600px) and (max-width: 1199px) {
      bottom: 20px;
      right: 32px;
    }
    @media (max-width: 599px) {
      bottom: 12px;
      right: 12px;
    }
  }
  > article {
    width: 100%;
    height: 100%;
    z-index: 1;
    .visual {
      width: 100%;
      height: 100%;
      &.alpha {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        > div {
          position: relative;

          ${media.belowTa} {
            height: 100%;
            display: flex;
            justify-content: center;
            left: 0.75rem;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            ${media.mo} {
              object-fit: contain;
            }
          }
        }
        ${media.mo} {
          .video {
            top: 20px;
            bottom: auto;
            left: 50%;
            right: auto;
            width: 183px;
            height: 400px;
            border-radius: 12px;
            overflow: hidden;
            isolation: isolate;
            transform: translateX(-50%);
          }
        }
      }
      &.beta {
        > div {
          position: relative;
          left: -3rem;
          height: 100%;

          ${media.belowTa} {
            left: 0;
            top: -3rem;
            display: flex;
            justify-content: center;
          }
          ${media.mo} {
            top: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            ${media.mo} {
              object-fit: contain;
            }
          }
        }
      }
      &.gamma {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        ${media.belowTa} {
          display: block;
        }
        > div {
          width: 15.125rem;
          height: 32.6875rem;

          ${media.belowTa} {
            position: absolute;
            width: 11.375rem;
            height: 24.625rem;
          }
          ${media.ta} {
            top: 0rem;
            right: 6rem;
          }
          ${media.mo} {
            top: -18rem;
            right: 1rem;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &.delta {
        > div {
          position: relative;
          left: -3rem;
          height: 100%;

          ${media.belowTa} {
            left: 0;
            display: flex;
            justify-content: center;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            ${media.belowTa} {
              width: 90%;
            }
          }
        }
      }
      &.epsilon {
        > div {
          position: relative;
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          ${media.mo} {
            top: -2rem;
          }
          .radar {
            position: absolute;
            width: 500px;
            height: 500px;
            background: url("/assets/images/radar_outer.svg") no-repeat center/cover;

            ${media.ta} {
              width: 378px;
              height: 378px;
            }

            ${media.mo} {
              width: 80%;
              height: 80%;
              background-size: contain;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;

              ${media.mo} {
                object-fit: contain;
              }
            }
          }
          .video {
            position: absolute;
            display: flex;
            width: 242px;
            height: 529px;
            border-radius: 12px;
            overflow: hidden;
            isolation: isolate;
            background-image: url("/assets/_videos/jp/feature_03.png");
            background-repeat: no-repeat;
            background-size: contain;

            ${media.belowTa} {
              width: 183px;
              height: 400px;
            }
            video {
              width: 100%;
            }
          }
          .sns {
            position: absolute;
            width: 8.1875rem;
            height: 8.1875rem;

            ${media.ta} {
              width: 6.25rem;
              height: 6.25rem;
            }

            ${media.mo} {
              width: 4.375rem;
              height: 4.375rem;
            }
          }
          .sns-twitter {
            top: 10rem;
            left: 3.5rem;

            ${media.ta} {
              top: 7.375rem;
              left: 6.4rem;
            }
            ${media.mo} {
              left: 1.8rem;
            }
          }
          .sns-youtube {
            top: 15rem;
            right: 4rem;

            ${media.ta} {
              top: 12.2rem;
              right: 6.1rem;
            }
            ${media.mo} {
              top: 14rem;
              right: 1.5rem;
            }
          }
          .sns-instagram {
            top: 25.625rem;
            left: 3rem;

            ${media.ta} {
              top: 20rem;
              left: 5.5rem;
            }
            ${media.mo} {
              top: 20rem;
              left: 1.5rem;
            }
          }
        }
      }
      &.zeta {
        > div {
          position: relative;
          height: 100%;

          ${media.belowTa} {
            left: 0;
            display: flex;
            justify-content: center;
          }
          ${media.mo} {
            top: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            ${media.mo} {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    > div {
      padding: 60px 0 0 40px;
      a {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 0;
    > div {
      height: auto;
      padding: 64px 0 0 20px;
      p {
        letter-spacing: -1px;
      }
    }
    > article {
      flex: 1;
      position: relative;
      width: 100%;
      height: auto;
    }
  }
`;

export class GripCheck extends React.Component {
  render() {
    return (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8333 21.5334L25.1749 12.1918C25.4652 11.9015 25.8347 11.7563 26.2833 11.7563C26.7319 11.7563 27.1013 11.9015 27.3916 12.1918C27.6819 12.482 27.827 12.8515 27.827 13.3001C27.827 13.7487 27.6819 14.1182 27.3916 14.4084L16.9416 24.8584C16.6249 25.1751 16.2555 25.3334 15.8333 25.3334C15.411 25.3334 15.0416 25.1751 14.7249 24.8584L10.6083 20.7418C10.318 20.4515 10.1729 20.082 10.1729 19.6334C10.1729 19.1848 10.318 18.8154 10.6083 18.5251C10.8985 18.2348 11.268 18.0897 11.7166 18.0897C12.1652 18.0897 12.5347 18.2348 12.8249 18.5251L15.8333 21.5334Z"
          fill="white"
        />
      </svg>
    );
  }
}
export class OtherCheck extends React.Component {
  render() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9998 13.6L15.8998 7.70005C16.0831 7.51672 16.3165 7.42505 16.5998 7.42505C16.8831 7.42505 17.1165 7.51672 17.2998 7.70005C17.4831 7.88338 17.5748 8.11671 17.5748 8.40005C17.5748 8.68338 17.4831 8.91672 17.2998 9.10005L10.6998 15.7C10.4998 15.9 10.2665 16 9.9998 16C9.73314 16 9.4998 15.9 9.2998 15.7L6.6998 13.1C6.51647 12.9167 6.4248 12.6834 6.4248 12.4C6.4248 12.1167 6.51647 11.8834 6.6998 11.7C6.88314 11.5167 7.11647 11.425 7.3998 11.425C7.68314 11.425 7.91647 11.5167 8.0998 11.7L9.9998 13.6Z"
          fill="#568DFD"
        />
      </svg>
    );
  }
}
