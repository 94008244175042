import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { PC } from "../MediaQuery";
import RollingVideo from "../RollingVideo";
import Typography from "../Typography";
import { FeatureCardWrap, GripCheck, OtherCheck } from "./FeatureCard.style";

interface FeatureCardProps {
  index: number;
  item: any;
  playable: boolean;
  language: string;
}

const GameTabs = [
  {
    id: 1,
    tab: "feature.card2.ox",
    icon: "/assets/images/icon_game_ox.png",
  },
  {
    id: 2,
    tab: "feature.card2.first",
    icon: "/assets/images/icon_game_first.png",
  },
  {
    id: 3,
    tab: "feature.card2.random",
    icon: "/assets/images/icon_game_random.png",
  },
];

const FeatureCard = (props: FeatureCardProps) => {
  const { index, item, playable, language } = props;
  const { t } = useTranslation("main");

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const [activeTab, setActiveTab] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const playSync = async (ref: HTMLVideoElement) => {
      try {
        await ref.play();
      } catch (error) {
        console.warn("failure by browser policy", error);

        try {
          await ref.play();
        } catch (err) {
          console.error(
            "[FeatureCard] Can't autoplay \nplease check and off row power mode your cell phone and some",
            err
          );
        }
      }
    };
    if (videoRef.current instanceof HTMLVideoElement) {
      if (!playable && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      } else if (playable && videoRef.current && videoRef.current.paused) {
        playSync(videoRef.current);
      }
    }
  }, [playable]);

  return (
    <FeatureCardWrap
      style={{
        width: isPc ? 1124 : isTa ? 630 : "100%",
        height: isPc ? 678 : isTa ? 764 : 718,
      }}
    >
      {isMo && <legend>0{index}</legend>}
      <div>
        <Typography
          label={
            <>
              <Trans i18nKey={item.title}>
                {item.title}
                <br />
                <br />
                <br />
              </Trans>
            </>
          }
          size={isPc ? (language === "ja" ? 56 : 48) : isTa ? 48 : 32}
          lineHeight={isPc ? (language === "ja" ? 68 : 56) : isTa ? 58 : 40}
          weight={700}
          color="white"
          mb={16}
        />
        <Typography
          label={
            <>
              <Trans i18nKey={item.description}>
                {item.description}
                <br />
                <br />
              </Trans>
            </>
          }
          size={!isMo ? 18 : 14}
          lineHeight={!isMo ? 26 : 20}
          color="#969696"
        />
        {index === 2 && isPc && (
          <div className="game-tab">
            {GameTabs.map((item, index) => (
              <button
                key={index}
                className={index === activeTab ? "active" : ""}
                onClick={() => setActiveTab(index)}
                style={{ padding: language === "ja" ? "12px 16px" : "12px" }}
              >
                <img src={item.icon} alt={item.tab} />
                <Typography label={t(item.tab)} size={isPc ? 16 : 14} />
              </button>
            ))}
          </div>
        )}
      </div>
      {item.comment && (
        <aside>
          <Typography
            label={
              <>
                <Trans i18nKey={item.comment}>
                  {item.comment}
                  <br />
                </Trans>
              </>
            }
            size={12}
            lineHeight={18}
            color="#9F9F9F"
          />
        </aside>
      )}
      <article>
        {index === 1 && (
          <div className="visual alpha">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("feature.card1.live")}</th>
                  <th>{t("feature.card1.vod")}</th>
                  <th>{t("feature.card1.short")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>GripCloud</td>
                  <td>
                    <GripCheck />
                  </td>
                  <td>
                    <GripCheck />
                  </td>
                  <td>
                    <GripCheck />
                  </td>
                </tr>
                <tr>
                  <td>A company</td>
                  <td>
                    <OtherCheck />
                  </td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>B company</td>
                  <td>
                    <OtherCheck />
                  </td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}></td>
                </tr>
              </tfoot>
            </table>
            {language === "ja" && (
              <PC>
                <div
                  className="video"
                  style={{ backgroundImage: 'url("/assets/_videos/jp/feature_01.png")' }}
                >
                  <video
                    ref={videoRef}
                    loop
                    muted
                    playsInline
                    preload="auto"
                    poster="/assets/_videos/jp/feature_01.png"
                    src="/assets/_videos/jp/feature_01.mp4"
                    onCanPlay={() => {
                      if (
                        playable &&
                        videoRef.current instanceof HTMLVideoElement &&
                        videoRef.current.paused
                      ) {
                        videoRef.current.play().catch((e) => {});
                      }
                    }}
                  />
                </div>
              </PC>
            )}
          </div>
        )}
        {index === 2 && (
          <div className="visual beta">
            <div
              className={`video ${language !== "ja" && "en"}`}
              style={{
                backgroundImage:
                  language === "ja"
                    ? 'url("/assets/_videos/jp/feature_02_1.png")'
                    : 'url("/assets/_videos/en/feature_02_1.png")',
              }}
            >
              {isPc ? (
                <>
                  {GameTabs[activeTab].id === 1 && (
                    <video
                      ref={videoRef}
                      loop
                      muted
                      playsInline
                      preload="auto"
                      poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_02_1.png`}
                      src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_02_1.mp4`}
                      onCanPlay={() => {
                        if (
                          playable &&
                          videoRef.current instanceof HTMLVideoElement &&
                          videoRef.current.paused
                        ) {
                          videoRef.current.play().catch((e) => {});
                        }
                      }}
                    />
                  )}
                  {GameTabs[activeTab].id === 2 && (
                    <video
                      ref={videoRef}
                      loop
                      muted
                      playsInline
                      preload="auto"
                      poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_02_2.png`}
                      src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_02_2.mp4`}
                      onCanPlay={() => {
                        if (
                          playable &&
                          videoRef.current instanceof HTMLVideoElement &&
                          videoRef.current.paused
                        ) {
                          videoRef.current.play().catch((e) => {});
                        }
                      }}
                    />
                  )}
                  {GameTabs[activeTab].id === 3 && (
                    <video
                      ref={videoRef}
                      loop
                      muted
                      playsInline
                      preload="auto"
                      poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_02_3.png`}
                      src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_02_3.mp4`}
                      onCanPlay={() => {
                        if (
                          playable &&
                          videoRef.current instanceof HTMLVideoElement &&
                          videoRef.current.paused
                        ) {
                          videoRef.current.play().catch((e) => {});
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <RollingVideo playable={playable} language={language} />
              )}
            </div>
            <PC>
              <div className="circle">
                <div>
                  <div>
                    <Typography label="60%" size={30} weight={700} color="white" />
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.1664 23.3333H12.8331V9.33327L6.41643 15.7499L4.75977 14.0933L13.9998 4.85327L23.2398 14.0933L21.5831 15.7499L15.1664 9.33327V23.3333Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <Typography
                    label={
                      <Trans i18nKey={"main:feature.card2.rate"}>
                        feature.card2.rate
                        <br />
                      </Trans>
                    }
                    size={10}
                    lineHeight={14}
                    color="white"
                  />
                </div>
              </div>
            </PC>
          </div>
        )}
        {index === 3 && (
          <div className="visual gamma">
            <div>
              <div className="radar">
                <img src="/assets/images/radar_inner.svg" alt="" />
              </div>
              <div
                className={`video ${language !== "ja" && "en"}`}
                style={{
                  backgroundImage:
                    language === "ja"
                      ? 'url("/assets/_videos/jp/feature_03.png")'
                      : 'url("/assets/_videos/en/feature_03.png")',
                }}
              >
                <video
                  ref={videoRef}
                  loop
                  muted
                  playsInline
                  preload="auto"
                  poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_03.png`}
                  src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_03.mp4`}
                  onCanPlay={() => {
                    if (
                      playable &&
                      videoRef.current instanceof HTMLVideoElement &&
                      videoRef.current.paused
                    ) {
                      videoRef.current.play().catch((e) => {});
                    }
                  }}
                />
              </div>
              <img className="sns-twitter" src="/assets/images/feature_sns_01.png" alt="" />
              <img className="sns-youtube" src="/assets/images/feature_sns_02.png" alt="" />
              <img className="sns-grip" src="/assets/images/feature_sns_03.png" alt="" />
            </div>
          </div>
        )}
        {index === 4 && (
          <div className="visual delta">
            <img className="dashboard" src="/assets/images/feature_04_01.png" alt="" />
            <div className="list">
              <Typography
                label={t("feature.card4.card.title")}
                size={16}
                weight={700}
                color="white"
              />
              {[1, 2, 3, 4].map((item) => {
                return (
                  <div key={item}>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.83366 11.9998L7.02116 10.1873C6.86838 10.0346 6.68088 9.95817 6.45866 9.95817C6.23644 9.95817 6.04199 10.0415 5.87533 10.2082C5.72255 10.3609 5.64616 10.5554 5.64616 10.7915C5.64616 11.0276 5.72255 11.2221 5.87533 11.3748L8.25033 13.7498C8.4031 13.9026 8.59755 13.979 8.83366 13.979C9.06977 13.979 9.26421 13.9026 9.41699 13.7498L14.1462 9.02067C14.2989 8.86789 14.3753 8.68039 14.3753 8.45817C14.3753 8.23595 14.292 8.0415 14.1253 7.87484C13.9725 7.72206 13.7781 7.64567 13.542 7.64567C13.3059 7.64567 13.1114 7.72206 12.9587 7.87484L8.83366 11.9998ZM10.0003 18.8332C8.84755 18.8332 7.76421 18.6143 6.75033 18.1765C5.73644 17.7393 4.85449 17.1457 4.10449 16.3957C3.35449 15.6457 2.76088 14.7637 2.32366 13.7498C1.88588 12.7359 1.66699 11.6526 1.66699 10.4998C1.66699 9.34706 1.88588 8.26373 2.32366 7.24984C2.76088 6.23595 3.35449 5.354 4.10449 4.604C4.85449 3.854 5.73644 3.26012 6.75033 2.82234C7.76421 2.38511 8.84755 2.1665 10.0003 2.1665C11.1531 2.1665 12.2364 2.38511 13.2503 2.82234C14.2642 3.26012 15.1462 3.854 15.8962 4.604C16.6462 5.354 17.2398 6.23595 17.677 7.24984C18.1148 8.26373 18.3337 9.34706 18.3337 10.4998C18.3337 11.6526 18.1148 12.7359 17.677 13.7498C17.2398 14.7637 16.6462 15.6457 15.8962 16.3957C15.1462 17.1457 14.2642 17.7393 13.2503 18.1765C12.2364 18.6143 11.1531 18.8332 10.0003 18.8332Z"
                        fill="#568DFD"
                      />
                    </svg>
                    <Typography
                      label={t(`feature.card4.card.desc${item}`)}
                      size={14}
                      weight={300}
                      color="white"
                    />
                  </div>
                );
              })}
            </div>
            <PC>
              <img className="chart" src="/assets/images/feature_04_02.png" alt="" />
            </PC>
          </div>
        )}
        {index === 5 && (
          <div className="visual epsilon">
            <div>
              <div
                className={`video ${language !== "ja" && "en"}`}
                style={{
                  backgroundImage:
                    language === "ja"
                      ? 'url("/assets/_videos/jp/feature_05.png")'
                      : 'url("/assets/_videos/en/feature_05.png")',
                }}
              >
                <video
                  ref={videoRef}
                  loop
                  muted
                  playsInline
                  preload="auto"
                  poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_05.png`}
                  src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/feature_05.mp4`}
                  onCanPlay={() => {
                    if (
                      playable &&
                      videoRef.current instanceof HTMLVideoElement &&
                      videoRef.current.paused
                    ) {
                      videoRef.current.play().catch((e) => {});
                    }
                  }}
                />
              </div>
              <div className="bubble-grid">
                <div>
                  <div className={`bubble bubble-01`}>
                    <Typography
                      label={
                        <Trans i18nKey={"main:feature.card5.bubble1"}>
                          feature.card5.bubble1
                          <br />
                        </Trans>
                      }
                      size={!isMo ? 18 : 12}
                      weight={700}
                    />
                  </div>
                  <div className={`bubble bubble-03`}>
                    <Typography
                      label={
                        <Trans i18nKey={"main:feature.card5.bubble2"}>
                          feature.card5.bubble2
                          <br />
                        </Trans>
                      }
                      size={!isMo ? 18 : 12}
                      weight={700}
                    />
                  </div>
                </div>
                <div></div>
                <div>
                  <div className={`bubble bubble-02`}>
                    <Typography
                      label={
                        <Trans i18nKey={"main:feature.card5.bubble3"}>
                          feature.card5.bubble3
                          <br />
                        </Trans>
                      }
                      size={!isMo ? 18 : 12}
                      weight={700}
                    />
                  </div>
                  <div className={`bubble bubble-04`}>
                    <Typography
                      label={
                        <Trans i18nKey={"main:feature.card5.bubble4"}>
                          feature.card5.bubble4
                          <br />
                        </Trans>
                      }
                      size={!isMo ? 18 : 12}
                      weight={700}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </article>
    </FeatureCardWrap>
  );
};

export default FeatureCard;
