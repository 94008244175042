import { useEffect, useRef, useState } from "react";

interface DividerProps {
  playable: boolean;
  language: string;
}

const RollingVideo = (props: DividerProps) => {
  const { playable, language } = props;
  const [isShowVideo1, setIsShowVideo1] = useState<boolean>(true);
  const [isShowVideo2, setIsShowVideo2] = useState<boolean>(false);
  const [isShowVideo3, setIsShowVideo3] = useState<boolean>(false);

  const videoRef1 = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);
  const videoRef3 = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const playSync = async (ref: HTMLVideoElement) => {
      try {
        await ref.play();
      } catch (error) {
        console.warn("failure by browser policy", error);

        try {
          await ref.play();
        } catch (err) {
          console.error(
            "[RollingVideo] Can't autoplay \nplease check and off row power mode your cell phone and some",
            err
          );
        }
      }
    };
    if (playable && isShowVideo1 && videoRef1.current instanceof HTMLVideoElement) {
      playSync(videoRef1.current);
    }
    if (playable && isShowVideo2 && videoRef2.current instanceof HTMLVideoElement) {
      playSync(videoRef2.current);
    }
    if (playable && isShowVideo3 && videoRef3.current instanceof HTMLVideoElement) {
      playSync(videoRef3.current);
    }
    if (!playable) {
      setIsShowVideo1(true);
      setIsShowVideo2(false);
      setIsShowVideo3(false);
    }
  }, [isShowVideo1, isShowVideo2, isShowVideo3, playable]);

  return (
    <>
      {isShowVideo1 && (
        <video
          ref={videoRef1}
          muted
          playsInline
          preload="auto"
          src={`/assets/_videos/${
            language === "ja" ? "jp" : language === "ko" ? "kr" : "en"
          }/feature_02_1.mp4`}
          onEnded={() => {
            if (playable && isShowVideo1 && videoRef1.current instanceof HTMLVideoElement) {
              videoRef1.current.pause();
              videoRef1.current.currentTime = 0;
              setIsShowVideo1(false);
              setIsShowVideo2(true);
            }
          }}
        />
      )}
      {isShowVideo2 && (
        <video
          ref={videoRef2}
          muted
          playsInline
          preload="auto"
          src={`/assets/_videos/${
            language === "ja" ? "jp" : language === "ko" ? "kr" : "en"
          }/feature_02_2.mp4`}
          onEnded={() => {
            if (playable && isShowVideo2 && videoRef2.current instanceof HTMLVideoElement) {
              videoRef2.current.pause();
              videoRef2.current.currentTime = 0;
              setIsShowVideo2(false);
              setIsShowVideo3(true);
            }
          }}
        />
      )}
      {isShowVideo3 && (
        <video
          ref={videoRef3}
          muted
          playsInline
          preload="auto"
          src={`/assets/_videos/${
            language === "ja" ? "jp" : language === "ko" ? "kr" : "en"
          }/feature_02_3.mp4`}
          onEnded={() => {
            if (playable && isShowVideo3 && videoRef3.current instanceof HTMLVideoElement) {
              videoRef3.current.pause();
              videoRef3.current.currentTime = 0;
              setIsShowVideo3(false);
              setIsShowVideo1(true);
            }
          }}
        />
      )}
    </>
  );
};

export default RollingVideo;
