import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import Typography from "../../Typography";
import { FeatureCardWrap } from "./FeatureCard.style";
import { screen } from "../../../../pages/home/Home.style";
import { useTranslation } from "react-i18next";

interface FeatureCardProps {
  index: number;
  item: any;
  playable: boolean;
}

const GameTabs = (language: string): { id: number; tab: string; icon: string }[] => [
  {
    id: 1,
    tab: language === "ko" ? "OX게임" : "OXゲーム",
    icon: "/assets/images/icon_game_ox.png",
  },
  {
    id: 2,
    tab: language === "ko" ? "선착순" : "先着順",
    icon: "/assets/images/icon_game_first.png",
  },
  {
    id: 3,
    tab: language === "ko" ? "추첨하기" : "抽選",
    icon: "/assets/images/icon_game_random.png",
  },
  {
    id: 4,
    tab: language === "ko" ? "객관식 / 주관식 퀴즈" : "クイズ",
    icon: "/assets/images/icon_game_quize.png",
  },
  {
    id: 5,
    tab: language === "ko" ? "설문 조사" : "アンケート",
    icon: "assets/images/icon_game_survey.png",
  },
  ...(language === "ko"
    ? [
        {
          id: 6,
          tab: "초성 퀴즈",
          icon: "assets/images/icon_game_initialquiz.png",
        },
      ]
    : []),
];

const FeatureCard = (props: FeatureCardProps) => {
  const {
    i18n: { language },
  } = useTranslation("main");
  const { index, item, playable } = props;

  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const playSync = async (ref: HTMLVideoElement) => {
      try {
        await ref.play();
      } catch (error) {
        console.warn("failure by browser policy", error);

        try {
          await ref.play();
        } catch (err) {
          console.error(
            "[FeatureCard] Can't autoplay \nplease check and off row power mode your cell phone and some",
            err
          );
        }
      }
    };
    if (videoRef.current instanceof HTMLVideoElement) {
      if (!playable && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      } else if (playable && videoRef.current && videoRef.current.paused) {
        playSync(videoRef.current);
      }
    }
  }, [playable]);

  return (
    <FeatureCardWrap
      style={{
        width: isPc ? 1124 : isTa ? 630 : "100%",
        height: isPc ? 678 : isTa ? 864 : 768,
      }}
    >
      {isMo && <legend>0{index}</legend>}
      <div>
        <Typography
          label={item.title}
          size={isPc ? 46 : isTa ? 48 : 32}
          lineHeight={isPc ? 60 : isTa ? 62 : 46}
          weight={700}
          color="white"
          mb={!isMo ? 24 : 12}
          style={{ whiteSpace: "nowrap" }}
        />
        <Typography
          label={item.desc}
          size={!isMo ? 16 : 14}
          lineHeight={!isMo ? 24 : 22}
          color="#969696"
        />

        {index === 3 && (
          <div className="game-tab">
            <div>
              {GameTabs(language).map((item, index) => (
                <div className="game-item" key={`game-item-${index}`}>
                  <img src={item.icon} alt={item.tab} />
                  <Typography
                    label={item.tab}
                    size={isPc ? 16 : 14}
                    {...(isPc && { style: { whiteSpace: "nowrap" } })}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <article>
        {index === 1 && (
          <div className="visual alpha">
            <div>
              <img
                src={
                  language === "ko"
                    ? "/assets/images/onclick_article.png"
                    : `/assets/images/onclick_article_${language}.png`
                }
                alt="onclick-article"
              />
            </div>
          </div>
        )}
        {index === 2 && (
          <div className="visual beta">
            <div>
              <img
                src={
                  language === "ko"
                    ? "/assets/images/data_dashboard_article.png"
                    : `/assets/images/data_dashboard_article_${language}.png`
                }
                alt="data-dashboard-article"
              />
            </div>
          </div>
        )}
        {index === 3 && (
          <div className="visual gamma">
            <div>
              <img
                src={
                  language === "ko"
                    ? "/assets/images/game_article.png"
                    : `/assets/images/game_article_${language}.png`
                }
                alt="game-article"
              />
            </div>
          </div>
        )}
        {index === 4 && (
          <div className="visual delta">
            <div>
              <img
                src={
                  language === "ko"
                    ? "/assets/images/vod_article.png"
                    : `/assets/images/vod_article_${language}.png`
                }
                alt="vod-article"
              />
            </div>
          </div>
        )}
        {index === 5 && (
          <div className="visual epsilon">
            <div>
              <div className="radar">
                <img src="/assets/images/radar_inner.svg" alt="" />
              </div>
              <div className="video">
                <video
                  ref={videoRef}
                  loop
                  muted
                  playsInline
                  preload="auto"
                  poster="/assets/_videos/jp/feature_03.png"
                  src="/assets/_videos/jp/feature_03.mp4"
                  onCanPlay={() => {
                    if (
                      playable &&
                      videoRef.current instanceof HTMLVideoElement &&
                      videoRef.current.paused
                    ) {
                      videoRef.current.play().catch((e) => {});
                    }
                  }}
                />
              </div>
              <img
                className="sns sns-twitter"
                src="/assets/images/feature_sns_01.png"
                alt="x-circle-logo"
              />
              <img
                className="sns sns-youtube"
                src="/assets/images/feature_sns_02.png"
                alt="youtube-circle-logo"
              />
              <img
                className="sns sns-instagram"
                src="/assets/images/feature_sns_04.png"
                alt="instagram-circle-logo"
              />
            </div>
          </div>
        )}
        {index === 6 && (
          <div className="visual zeta">
            <div>
              <img
                src={
                  language === "ko"
                    ? "/assets/images/prompter_article.png"
                    : `/assets/images/prompter_article_${language}.png`
                }
                alt="prompter-article"
              />
            </div>
          </div>
        )}
      </article>
    </FeatureCardWrap>
  );
};

export default FeatureCard;
