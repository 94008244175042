import React, { useState, useRef } from "react";
import Reveal from "react-awesome-reveal";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { WhyGrip, WhyOther } from "../../../pages/home/Home.data";
import { HomeSectionInner, fadeUp, HomeSection, fadeIn } from "../../../pages/home/Home.style";
import { Mobile, PC, Tablet } from "../MediaQuery";
import Typography from "../Typography";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import useIntersectionObserver from "../useIntersectionObserver";

const WhySectionWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 90px;
  .article-pc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .why-comparison {
      margin-top: 56px;
    }
  }
  .why-video {
    display: flex;
    width: 290px;
    height: 634px;
    border-radius: 12px;
    overflow: hidden;
    isolation: isolate;
    box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
    background-repeat: no-repeat;
    background-size: contain;
    video {
      width: 100%;
    }
    &.en {
      height: 628px;
    }
  }
  .why-comparison {
    display: flex;
    gap: 0 32px;
    figure {
      ul {
        li {
          display: flex;
          align-items: center;
          gap: 0 12px;
          padding: 6px 0;
        }
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    gap: 48px 0;
    > div {
      display: flex;
      gap: 0 54px;
      > div {
        display: flex;
        align-items: center;
      }
    }
    .why-video {
      width: 236px;
      height: 516px;
      border-radius: 12px;
      overflow: hidden;
      isolation: isolate;
      &.en {
        height: 511px;
      }
    }
    .why-comparison {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 48px 0;
      figure {
        ul {
          li {
            gap: 0 4px;
            padding: 4px 0;
          }
        }
      }
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
    aside {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 26px;
    }
    .why-video {
      width: 188px;
      height: 411px;
      border-radius: 12px;
      overflow: hidden;
      isolation: isolate;
      &.en {
        height: 407px;
      }
    }
    .why-comparison {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 560px;
      margin: 0 auto;
      figure {
        ul {
          li {
            gap: 0 4px;
            padding: 4px 0;
          }
        }
      }
    }
    .slick-dots {
      bottom: -30px;
    }
  }
`;

interface WhySectionProps {
  language: string;
}

const WhySection = (props: WhySectionProps) => {
  const { language } = props;
  const { t } = useTranslation("main");

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isTa = useMediaQuery({ query: "(min-width:600px) and (max-width:1199px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const videoRefPc = useRef<HTMLVideoElement>(null);
  const videoRefTa = useRef<HTMLVideoElement>(null);
  const videoRefMo = useRef<HTMLVideoElement>(null);
  const comparisonSlideEl = useRef<any>(null);
  const [comparisonCurrentSlide, setComparisonCurrentSlide] = useState<number>(0);
  const [checkAni, setCheckAni] = useState<boolean>(false);

  const renderList = (item: any, checkAni?: boolean) => {
    return (
      <figure style={{ width: isMo ? 280 : "auto", minWidth: isPc ? 390 : "auto", flex: 1 }}>
        <Typography
          label={t(item.title)}
          size={isPc ? 38 : isTa ? 28 : 24}
          weight={700}
          mb={!isPc ? 12 : 20}
        />
        <ul>
          {item.list.map((sub: any, i: number) => {
            const delay = (i + 1) * 0.5;
            return (
              <li key={i}>
                {isPc ? (
                  <svg width="32" height="33" viewBox="0 0 32 33" fill="none">
                    <path
                      d="M14.1337 18.8996L11.2337 15.9996C10.9892 15.7551 10.6892 15.6329 10.3337 15.6329C9.9781 15.6329 9.66699 15.7663 9.40033 16.0329C9.15588 16.2774 9.03366 16.5885 9.03366 16.9663C9.03366 17.344 9.15588 17.6551 9.40033 17.8996L13.2003 21.6996C13.4448 21.944 13.7559 22.0663 14.1337 22.0663C14.5114 22.0663 14.8225 21.944 15.067 21.6996L22.6337 14.1329C22.8781 13.8885 23.0003 13.5885 23.0003 13.2329C23.0003 12.8774 22.867 12.5663 22.6003 12.2996C22.3559 12.0551 22.0448 11.9329 21.667 11.9329C21.2892 11.9329 20.9781 12.0551 20.7337 12.2996L14.1337 18.8996ZM16.0003 29.8329C14.1559 29.8329 12.4225 29.4827 10.8003 28.7823C9.1781 28.0827 7.76699 27.1329 6.56699 25.9329C5.36699 24.7329 4.41721 23.3218 3.71766 21.6996C3.01721 20.0774 2.66699 18.344 2.66699 16.4996C2.66699 14.6551 3.01721 12.9218 3.71766 11.2996C4.41721 9.67737 5.36699 8.26626 6.56699 7.06626C7.76699 5.86626 9.1781 4.91604 10.8003 4.21559C12.4225 3.51604 14.1559 3.16626 16.0003 3.16626C17.8448 3.16626 19.5781 3.51604 21.2003 4.21559C22.8225 4.91604 24.2337 5.86626 25.4337 7.06626C26.6337 8.26626 27.5834 9.67737 28.283 11.2996C28.9834 12.9218 29.3337 14.6551 29.3337 16.4996C29.3337 18.344 28.9834 20.0774 28.283 21.6996C27.5834 23.3218 26.6337 24.7329 25.4337 25.9329C24.2337 27.1329 22.8225 28.0827 21.2003 28.7823C19.5781 29.4827 17.8448 29.8329 16.0003 29.8329Z"
                      style={{
                        fill: checkAni ? "#568DFD" : "#D3D3D3",
                        transition: checkAni ? `all 0.3s ${delay}s ease` : `all 0s 0s ease`,
                      }}
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83366 11.4998L7.02116 9.68734C6.86838 9.53456 6.68088 9.45817 6.45866 9.45817C6.23644 9.45817 6.04199 9.5415 5.87533 9.70817C5.72255 9.86095 5.64616 10.0554 5.64616 10.2915C5.64616 10.5276 5.72255 10.7221 5.87533 10.8748L8.25033 13.2498C8.4031 13.4026 8.59755 13.479 8.83366 13.479C9.06977 13.479 9.26421 13.4026 9.41699 13.2498L14.1462 8.52067C14.2989 8.36789 14.3753 8.18039 14.3753 7.95817C14.3753 7.73595 14.292 7.5415 14.1253 7.37484C13.9725 7.22206 13.7781 7.14567 13.542 7.14567C13.3059 7.14567 13.1114 7.22206 12.9587 7.37484L8.83366 11.4998ZM10.0003 18.3332C8.84755 18.3332 7.76421 18.1143 6.75033 17.6765C5.73644 17.2393 4.85449 16.6457 4.10449 15.8957C3.35449 15.1457 2.76088 14.2637 2.32366 13.2498C1.88588 12.2359 1.66699 11.1526 1.66699 9.99984C1.66699 8.84706 1.88588 7.76373 2.32366 6.74984C2.76088 5.73595 3.35449 4.854 4.10449 4.104C4.85449 3.354 5.73644 2.76012 6.75033 2.32234C7.76421 1.88511 8.84755 1.6665 10.0003 1.6665C11.1531 1.6665 12.2364 1.88511 13.2503 2.32234C14.2642 2.76012 15.1462 3.354 15.8962 4.104C16.6462 4.854 17.2398 5.73595 17.677 6.74984C18.1148 7.76373 18.3337 8.84706 18.3337 9.99984C18.3337 11.1526 18.1148 12.2359 17.677 13.2498C17.2398 14.2637 16.6462 15.1457 15.8962 15.8957C15.1462 16.6457 14.2642 17.2393 13.2503 17.6765C12.2364 18.1143 11.1531 18.3332 10.0003 18.3332Z"
                      style={{
                        fill: checkAni ? "#568DFD" : "#D3D3D3",
                        transition: checkAni ? `all 0.3s ${delay}s ease` : `all 0s 0s ease`,
                      }}
                    />
                  </svg>
                )}
                <Typography
                  label={t(sub.description)}
                  size={isPc ? 22 : isTa ? 16 : 14}
                  weight={500}
                />
              </li>
            );
          })}
        </ul>
      </figure>
    );
  };

  const comparisonSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    // autoplay: true,
    arrows: false,
    beforeChange: (current: any, next: number) => {
      setComparisonCurrentSlide(next);
    },
    appendDots: (dots: any) => (
      <div>
        <ul className="light">{dots}</ul>
      </div>
    ),
    customPaging: (index: any) => (
      <button
        style={{
          backgroundColor: comparisonCurrentSlide >= index ? "#568DFD" : undefined,
          borderTopRightRadius: comparisonCurrentSlide === index ? "3px" : 0,
          borderBottomRightRadius: comparisonCurrentSlide === index ? "3px" : 0,
        }}
      />
    ),
  };

  const onIntersect: IntersectionObserverCallback = async ([{ isIntersecting }]) => {
    if (videoRefMo.current instanceof HTMLVideoElement) {
      if (isIntersecting) {
        try {
          await videoRefMo.current.play();
        } catch (error) {
          console.warn("failure by browser policy", error);

          try {
            await videoRefMo.current.play();
          } catch (err) {
            console.error(
              "[WhySection] Can't autoplay \nplease check and off row power mode your cell phone and some",
              err
            );
          }
        }
      } else {
        videoRefMo.current.pause();
        videoRefMo.current.currentTime = 0;
      }
    }
  };

  const { setTarget } = useIntersectionObserver({ onIntersect });

  return (
    <HomeSection pt={isMo ? 100 : 200} pb={isMo ? 100 : 200}>
      <HomeSectionInner>
        <WhySectionWrap>
          <PC>
            <aside>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={200}
                onVisibilityChange={async (inView: boolean, _: IntersectionObserverEntry) => {
                  if (videoRefPc.current instanceof HTMLVideoElement) {
                    if (inView) {
                      try {
                        await videoRefPc.current.play();
                      } catch (error) {
                        console.warn("failure by browser policy", error);

                        try {
                          await videoRefPc.current.play();
                        } catch (err) {
                          console.error(
                            "[WhySection] Can't autoplay \nplease check and off row power mode your cell phone and some",
                            err
                          );
                        }
                      }
                    } else {
                      videoRefPc.current.pause();
                      videoRefPc.current.currentTime = 0;
                    }
                  }
                }}
              >
                <div
                  className={`why-video ${language !== "ja" && "en"}`}
                  style={{
                    backgroundImage:
                      language === "ja"
                        ? 'url("/assets/_videos/jp/why.png")'
                        : 'url("/assets/_videos/en/why.png")',
                  }}
                >
                  <video
                    ref={videoRefPc}
                    loop
                    muted
                    playsInline
                    preload="auto"
                    poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/why.png`}
                    src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/why.mp4`}
                  />
                </div>
              </Reveal>
            </aside>
            <article className="article-pc">
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography label="Why Grip" size={32} weight={700} color="#568DFD" mb={16} />
                <Typography
                  label={
                    <Trans i18nKey={"main:why.title"}>
                      why.title
                      <br />
                    </Trans>
                  }
                  size={90}
                  weight={800}
                />
              </Reveal>
              <Reveal
                className="reveal"
                keyframes={fadeIn}
                duration={1000}
                delay={200}
                onVisibilityChange={(inView: boolean, _: IntersectionObserverEntry) => {
                  if (inView) {
                    setCheckAni(true);
                  } else {
                    setCheckAni(false);
                  }
                }}
              >
                <div className="why-comparison">
                  {WhyGrip.map((item: any, i: number) => {
                    return <React.Fragment key={i}>{renderList(item, checkAni)}</React.Fragment>;
                  })}
                  {WhyOther.map((item: any, i: number) => {
                    return <React.Fragment key={i}>{renderList(item)}</React.Fragment>;
                  })}
                </div>
              </Reveal>
            </article>
          </PC>
          <Tablet>
            <article>
              <Reveal
                className="reveal"
                keyframes={fadeUp}
                duration={600}
                delay={100}
                cascade
                damping={0.25}
              >
                <Typography label="Why Grip" size={24} weight={700} color="#568DFD" mb={12} />
                <Typography
                  label={
                    <Trans i18nKey={"main:why.title"}>
                      why.title
                      <br />
                    </Trans>
                  }
                  size={72}
                  weight={800}
                />
              </Reveal>
            </article>
            <div>
              <aside>
                <Reveal
                  className="reveal"
                  keyframes={fadeUp}
                  duration={600}
                  delay={200}
                  onVisibilityChange={async (inView: boolean, _: IntersectionObserverEntry) => {
                    if (videoRefTa.current instanceof HTMLVideoElement) {
                      if (inView) {
                        try {
                          await videoRefTa.current.play();
                        } catch (error) {
                          console.warn("failure by browser policy", error);

                          try {
                            await videoRefTa.current.play();
                          } catch (err) {
                            console.error(
                              "[WhySection] Can't autoplay \nplease check and off row power mode your cell phone and some",
                              err
                            );
                          }
                        }
                      } else {
                        videoRefTa.current.pause();
                        videoRefTa.current.currentTime = 0;
                      }
                    }
                  }}
                >
                  <div
                    className={`why-video ${language !== "ja" && "en"}`}
                    style={{
                      backgroundImage:
                        language === "ja"
                          ? 'url("/assets/_videos/jp/why.png")'
                          : 'url("/assets/_videos/en/why.png")',
                    }}
                  >
                    <video
                      ref={videoRefTa}
                      loop
                      muted
                      playsInline
                      preload="auto"
                      poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/why.png`}
                      src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/why.mp4`}
                    />
                  </div>
                </Reveal>
              </aside>
              <Reveal
                className="reveal"
                keyframes={fadeIn}
                duration={1000}
                delay={200}
                onVisibilityChange={(inView: boolean, entry: IntersectionObserverEntry) => {
                  if (inView) {
                    setCheckAni(true);
                  } else {
                    setCheckAni(false);
                  }
                }}
              >
                <div className="why-comparison">
                  {WhyGrip.map((item: any, i: number) => {
                    return <React.Fragment key={i}>{renderList(item, checkAni)}</React.Fragment>;
                  })}
                  {WhyOther.map((item: any, i: number) => {
                    return <React.Fragment key={i}>{renderList(item)}</React.Fragment>;
                  })}
                </div>
              </Reveal>
            </div>
          </Tablet>
          <Mobile>
            <article>
              <Typography label="Why Grip" size={16} weight={700} color="#568DFD" mb={12} />
              <Typography
                label={
                  <Trans i18nKey={"main:why.title"}>
                    why.title
                    <br />
                  </Trans>
                }
                size={36}
                weight={800}
              />
            </article>
            <aside>
              <div
                ref={setTarget}
                className={`why-video ${language !== "ja" && "en"}`}
                style={{
                  backgroundImage:
                    language === "ja"
                      ? 'url("/assets/_videos/jp/why.png")'
                      : 'url("/assets/_videos/en/why.png")',
                }}
              >
                <video
                  ref={videoRefMo}
                  loop
                  muted
                  playsInline
                  preload="auto"
                  poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/why.png`}
                  src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/why.mp4`}
                />
              </div>
            </aside>
            <Reveal
              className="reveal"
              keyframes={fadeIn}
              duration={1000}
              delay={200}
              onVisibilityChange={(inView: boolean, entry: IntersectionObserverEntry) => {
                if (inView) {
                  setCheckAni(true);
                } else {
                  setCheckAni(false);
                }
              }}
            >
              <div className="why-comparison">
                <Slider ref={comparisonSlideEl} {...comparisonSettings}>
                  {WhyGrip.map((item: any, i: number) => {
                    return <React.Fragment key={i}>{renderList(item, checkAni)}</React.Fragment>;
                  })}
                  {WhyOther.map((item: any, i: number) => {
                    return <React.Fragment key={i}>{renderList(item)}</React.Fragment>;
                  })}
                </Slider>
              </div>
            </Reveal>
          </Mobile>
        </WhySectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default WhySection;
