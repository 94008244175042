import Reveal from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import {
  fadeIn,
  fadeUp,
  HomeSection,
  HomeSectionInner,
  media,
  screen,
} from "../../../../pages/home/Home.style";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import InnerTypography from "../../InnerTypography";

const IntroSectionWrap = styled.div`
  position: relative;
  height: 658px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.belowTa} {
    grid-template-columns: 1fr;
  }

  > article {
    justify-self: center;
    /* padding: 100px 0 0 40px; */
    padding: 100px 0 0 0;

    ${media.belowTa} {
      justify-self: initial;
    }
  }
  > aside {
    position: absolute;
    top: -40px;
    right: 0;
    .intro-video {
      display: flex;
      width: 322px;
      height: 654px;
      border-radius: 12px;
      box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      isolation: isolate;
      background-image: url("/assets/_videos/kr/intro.png");
      background-repeat: no-repeat;
      background-size: contain;
      video {
        width: 100%;
      }
    }

    .intro-image {
      display: flex;
      width: 100%;
      height: 874px;
      border-radius: 12px;
      overflow: hidden;
      isolation: isolate;

      ${media.ta} {
        height: initial;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  ${media.ta} {
    height: auto;
    > article {
      padding: 0;
    }
    > aside {
      position: relative;
      display: flex;
      justify-content: center;
      right: auto;
      margin-top: 60px;
    }
  }

  ${media.mo} {
    height: auto;
    > article {
      padding: 40px 20px 0;
    }
    > aside {
      position: relative;
      display: flex;
      justify-content: center;
      right: auto;
      margin-top: 44px;
      .intro-video {
        width: 260px;
        height: 528px;
        border-radius: 12px;
        overflow: hidden;
        isolation: isolate;
      }
      .intro-image {
        height: 100%;
      }
    }
  }
`;

interface IntroSectionProps {
  onClick: () => void;
}

const IntroSection = (props: IntroSectionProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("main");
  const { onClick } = props;
  const isPc = useMediaQuery({ query: screen.pc });
  const isTa = useMediaQuery({ query: screen.ta });
  const isMo = useMediaQuery({ query: screen.mo });

  return (
    <HomeSection pt={isPc ? 160 : isTa ? 70 : 34} pb={isPc ? 80 : isTa ? 30 : 20}>
      <HomeSectionInner>
        <IntroSectionWrap>
          {!isMo ? (
            <>
              <article>
                <Reveal
                  className="reveal"
                  keyframes={fadeUp}
                  duration={600}
                  delay={100}
                  cascade
                  damping={0.25}
                >
                  <InnerTypography.Header fontWeight={700} fontSize={"3.5rem"}>
                    {language === "ko" ? "자사몰 성장을 위한" : "D2C成長のための"}
                  </InnerTypography.Header>
                  <InnerTypography.Header fontWeight={700} fontSize={"3.5rem"} color="#003D99">
                    {language === "ko" ? "미디어커머스 솔루션" : "動画ソリューション"}
                  </InnerTypography.Header>
                  <InnerTypography.Paragraph
                    fontSize={"1.25rem"}
                    margin={isTa ? "1rem 0 3.12rem" : "2rem 0 5rem"}
                  >
                    {language === "ko"
                      ? "차별화된 커머스와 콘텐츠로 고객이 자주 찾는 스토어를 만드세요."
                      : "メディアコマースでリピートしたくなるECサイトに"}
                  </InnerTypography.Paragraph>
                  <Button
                    className="gtm-trigger-request-demo"
                    label={language === "ko" ? `${t("inquire")}하기` : "お問い合わせ"}
                    onClick={onClick}
                  />
                </Reveal>
              </article>
              <aside>
                <Reveal
                  className="reveal"
                  keyframes={isMo ? fadeIn : fadeUp}
                  duration={isMo ? 300 : 600}
                  delay={isMo ? 0 : 200}
                >
                  <div className="intro-image">
                    <img
                      className="pc-intro-cover"
                      src={
                        language === "ko"
                          ? "/intro_image_cover.png"
                          : `/intro_image_cover_${language}.png`
                      }
                      alt="intro-cover"
                    />
                  </div>
                </Reveal>
              </aside>
            </>
          ) : (
            <>
              <article>
                <InnerTypography.Header fontWeight={700} fontSize={"2.25rem"}>
                  {language === "ko" ? "자사몰 성장을 위한" : "D2C成長のための"}
                </InnerTypography.Header>
                <InnerTypography.Header fontWeight={700} fontSize={"2.25rem"} color="#003D99">
                  {language === "ko" ? "미디어커머스 솔루션" : "動画ソリューション"}
                </InnerTypography.Header>
                <InnerTypography.Paragraph
                  fontSize={"1rem"}
                  marginBottom={"1.5rem"}
                  maxWidth={"17.8125rem"}
                >
                  {language === "ko"
                    ? "차별화된 커머스와 콘텐츠로 고객이 자주 찾는 스토어를 만드세요."
                    : "メディアコマースでリピートしたくなるECサイトに"}
                </InnerTypography.Paragraph>
                <Button
                  className="gtm-trigger-request-demo"
                  label={language === "ko" ? `${t("inquire")}하기` : "お問い合わせ"}
                  onClick={onClick}
                  style={{ maxWidth: "10rem", height: 52 }}
                />
              </article>
              <aside>
                <Reveal
                  className="reveal"
                  keyframes={isMo ? fadeIn : fadeUp}
                  duration={isMo ? 300 : 600}
                  delay={isMo ? 0 : 200}
                >
                  <div className="intro-image">
                    <img
                      className="mobile-intro-cover"
                      src={
                        language === "ko"
                          ? "/mobile_intro_image_cover.png"
                          : `/mobile_intro_image_cover_${language}.png`
                      }
                      alt="mobile-intro-cover"
                    />
                  </div>
                </Reveal>
              </aside>
            </>
          )}
        </IntroSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default IntroSection;
