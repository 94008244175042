import { useRef } from "react";
import Reveal from "react-awesome-reveal";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { HomeSectionInner, fadeUp, HomeSection } from "../../../pages/home/Home.style";
import Typography from "../Typography";
import Button from "../Button";
import useIntersectionObserver from "../useIntersectionObserver";

const IntroSectionWrap = styled.div`
  position: relative;
  height: 625px;
  > article {
    padding-top: 24px;
  }
  > aside {
    position: absolute;
    top: 0;
    right: 40px;
    .intro-video {
      display: flex;
      width: 290px;
      height: 634px;
      border-radius: 12px;
      overflow: hidden;
      isolation: isolate;
      background-repeat: no-repeat;
      background-size: contain;
      box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
      video {
        width: 100%;
      }
      &.en {
        height: 628px;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    height: 680px;
    > aside {
      height: auto;
      top: auto;
      bottom: 0;
      right: 40px;
      .intro-video {
        width: 215px;
        height: 470px;
        border-radius: 12px;
        overflow: hidden;
        isolation: isolate;
        &.en {
          width: 218px;
          height: 472px;
        }
      }
    }
  }

  @media (max-width: 599px) {
    height: auto;
    > aside {
      position: relative;
      display: flex;
      justify-content: center;
      right: auto;
      .intro-video {
        width: 215px;
        height: 470px;
        border-radius: 12px;
        overflow: hidden;
        isolation: isolate;
        &.en {
          width: 218px;
          height: 472px;
        }
      }
    }
  }
`;

interface IntroSectionProps {
  language: string;
  onClick: () => void;
}

const IntroSection = (props: IntroSectionProps) => {
  const { language, onClick } = props;
  const { t } = useTranslation("main");

  const isPc = useMediaQuery({ query: "(min-width:1200px)" });
  const isMo = useMediaQuery({ query: "(max-width:599px)" });

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoRefMo = useRef<HTMLVideoElement>(null);

  const onIntersect: IntersectionObserverCallback = async ([{ isIntersecting }]) => {
    if (videoRefMo.current instanceof HTMLVideoElement) {
      if (isIntersecting) {
        try {
          await videoRefMo.current.play();
        } catch (error) {
          console.warn("failure by browser policy", error);

          try {
            await videoRefMo.current.play();
          } catch (err) {
            console.error(
              "[IntroSection] Can't autoplay \nplease check and off row power mode your cell phone and some",
              err
            );
          }
        }
      } else {
        videoRefMo.current.pause();
        videoRefMo.current.currentTime = 0;
      }
    }
  };

  const { setTarget } = useIntersectionObserver({ onIntersect });

  return (
    <HomeSection pb={54} pt={isMo ? 40 : undefined}>
      <HomeSectionInner>
        <IntroSectionWrap>
          {!isMo ? (
            <>
              <article>
                <Reveal
                  className="reveal"
                  keyframes={fadeUp}
                  duration={600}
                  delay={100}
                  cascade
                  damping={0.25}
                >
                  <Typography
                    label={t("intro.title1")}
                    size={isPc ? 100 : 80}
                    weight={800}
                    mb={16}
                  />
                  <Typography
                    label={t("intro.title2")}
                    size={isPc ? 100 : 80}
                    weight={800}
                    mb={16}
                  />
                  <Typography label={t("intro.title3")} size={isPc ? 100 : 80} weight={800} />
                  <Typography
                    label={
                      <Trans i18nKey={"main:intro.desc"}>
                        intro.desc
                        <br />
                      </Trans>
                    }
                    size={24}
                    lineHeight={36}
                    mt={24}
                    mb={40}
                  />
                  <Button label={t("intro.btn")} onClick={onClick} />
                </Reveal>
              </article>
              <aside>
                <Reveal
                  className="reveal"
                  keyframes={fadeUp}
                  duration={600}
                  delay={200}
                  onVisibilityChange={async (inView: boolean, _: IntersectionObserverEntry) => {
                    if (videoRef.current instanceof HTMLVideoElement) {
                      if (inView) {
                        try {
                          await videoRef.current.play();
                        } catch (error) {
                          console.warn("failure by browser policy", error);

                          try {
                            await videoRef.current.play();
                          } catch (err) {
                            console.error(
                              "[IntroSection] Can't autoplay \nplease check and off row power mode your cell phone and some",
                              err
                            );
                          }
                        }
                      } else {
                        videoRef.current.pause();
                        videoRef.current.currentTime = 0;
                      }
                    }
                  }}
                >
                  <div
                    className={`intro-video ${language !== "ja" && "en"}`}
                    style={{
                      backgroundImage:
                        language === "ja"
                          ? 'url("/assets/_videos/jp/intro.png")'
                          : 'url("/assets/_videos/en/intro.png")',
                    }}
                  >
                    <video
                      ref={videoRef}
                      loop
                      muted
                      playsInline
                      preload="auto"
                      src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/intro.mp4`}
                    />
                  </div>
                </Reveal>
              </aside>
            </>
          ) : (
            <>
              <article>
                <Typography label={t("intro.title1")} size={44} weight={800} mb={12} />
                <Typography label={t("intro.title2")} size={44} weight={800} mb={12} />
                <Typography label={t("intro.title3")} size={44} weight={800} />
                <Typography
                  label={
                    <Trans i18nKey={"main:intro.desc"}>
                      intro.desc
                      <br />
                    </Trans>
                  }
                  size={16}
                  lineHeight={26}
                  mt={24}
                  mb={40}
                />
              </article>
              <aside>
                <div
                  ref={setTarget}
                  className={`intro-video ${language !== "ja" && "en"}`}
                  style={{
                    backgroundImage:
                      language === "ja"
                        ? 'url("/assets/_videos/jp/intro.png")'
                        : 'url("/assets/_videos/en/intro.png")',
                  }}
                >
                  <video
                    ref={videoRefMo}
                    loop
                    muted
                    playsInline
                    preload="auto"
                    poster={`/assets/_videos/${language === "ja" ? "jp" : "en"}/intro.png`}
                    src={`/assets/_videos/${language === "ja" ? "jp" : "en"}/intro.mp4`}
                  />
                </div>
              </aside>
            </>
          )}
        </IntroSectionWrap>
      </HomeSectionInner>
    </HomeSection>
  );
};

export default IntroSection;
